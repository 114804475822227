
import Vue from "vue";
import ListTile from "@/components/molecules/lists/ListTile.vue";
import ImageViewRound from "@/components/atoms/ImageViewRound.vue";
import { LayoutEnum, MaterialFolder, NovelMaterial } from "@/lib/models";
import CheckboxBlankOutlineIcon from "icons/CheckboxBlankOutline.vue";
import CheckboxMarkedOutlineIcon from "icons/CheckboxMarkedOutline.vue";
import MaterialFolderEditDialog, {
  MaterialFolderSelectDialogProps,
} from "@/components/ui/MaterialFolderSelectDialog.vue";
import { Dialog } from "@/lib/utils";

const textIcon = require("@/assets/img/icon/text_icon.png");

interface Methods {
  onClick: (event: PointerEvent) => void;
  onCheck: (event: PointerEvent) => void;
  onFolderIconClick: (event: PointerEvent) => void;
  updateFolder: (folder: MaterialFolder, materialId: string, includes: boolean) => MaterialFolder;
}

interface Computed {
  itemName: string;
  itemSrc: string;
  isDictionary: boolean;
  isActive: boolean;
}

interface Props {
  material: NovelMaterial;
  isListEditing: boolean;
  isDeleteEditing: boolean;
  handleClassName: string;
  checked: boolean;
  addDeleteMaterial: (material: NovelMaterial) => void;
  refresh: () => void;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: { ListTile, ImageViewRound, CheckboxBlankOutlineIcon, CheckboxMarkedOutlineIcon },
  props: {
    material: Object,
    isListEditing: {
      type: Boolean,
      default: false,
    },
    isDeleteEditing: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    handleClassName: {
      type: String,
      default: "handle",
    },
    addDeleteMaterial: Function,
    refresh: Function,
  },
  computed: {
    itemName() {
      const { material } = this;
      const first = material.items[0];
      if (first.value) {
        return first.value;
      }

      return "名称未設定";
    },
    itemSrc() {
      const { material, isDictionary } = this;
      if (isDictionary) {
        return textIcon;
      }

      return material.items[2].imagePath;
    },
    isDictionary() {
      const { material } = this;
      return material.layout === LayoutEnum.DICTIONARY;
    },
    isActive() {
      const { $route, material } = this;
      return $route.params.materialId === material.id;
    },
  },

  methods: {
    /**
     * リストアイテム選択のハンドラ
     * @param _: クリックオブジェクト
     */
    onClick(_: PointerEvent) {
      const { $store, material, isListEditing, isDeleteEditing } = this;
      const materialId = material.id;
      const { attributeId } = material;

      if (isListEditing || isDeleteEditing) return;

      // 資料IDをindexedDBに格納
      $store.dispatch("materialModule/openMaterial", { attributeId, materialId });

      if (materialId === this.$route.params.materialId) return;

      const params = { ...this.$route.params, materialId };
      if (this.$route.name) {
        this.$router.push({ name: this.$route.name, params });
      }
    },
    onCheck() {
      const { material, addDeleteMaterial } = this;
      addDeleteMaterial(material);
    },
    /**
     * フォルダ選択のハンドラ
     * @param _: クリックオブジェクト
     */
    onFolderIconClick(_: PointerEvent) {
      const { material, updateFolder, refresh } = this;
      const { getters, dispatch } = this.$store;
      const folders = getters["materialModule/foldersFromAttribute"](material.attributeId) as MaterialFolder[];

      const positiveCallback = async (ids: string[]) => {
        const newFolders: any[] = [];
        for (let index = 0; index < folders.length; index += 1) {
          const folder = folders[index];
          newFolders.push(updateFolder(folder, material.id, ids.includes(folder.id)));
        }

        await dispatch("materialModule/updateMaterialFolder", newFolders);
        refresh();
      };

      const folderSelectDialog = new Dialog(MaterialFolderEditDialog);
      const data: MaterialFolderSelectDialogProps = {
        title: `フォルダの選択`,
        content: "格納先のフォルダを選択してください\n※複数選択可",
        materialId: material.id,
        items: folders,
        positiveCallback,
      };
      folderSelectDialog.show(data);
    },
    updateFolder(folder, materialId, includes) {
      const result = { ...folder };
      // 追加
      if (includes) {
        // もともと入っていた場合はそのまま
        if (folder.materialOrder.includes(materialId)) {
          return result;
        }

        // ない場合は追加
        result.materialOrder = result.materialOrder.concat(materialId);
        return result;
      }

      // 削除
      if (!folder.materialOrder.includes(materialId)) {
        return result;
      }

      result.materialOrder = result.materialOrder.filter((id) => id !== materialId);

      return result;
    },
  },
});
